const propertyData = {
    "url": "https://3460oakcrestdr.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "3460 Oakcrest Dr", "city": "Forest Grove", "state":"Oregon", "zipcode": "97116"},
    "display_address": "3460 Oakcrest Dr",
    "property": {
      "listing_price": "$469,900",
      "bedrooms": "4",
      "baths": "2.5",
      "sq_feet": "2454",
      "lot_size": "8276",
      "rmls": "19079345",
      "built_in": "2014",
      "blurb": "Charming two-story 4 bed/ 2.5 bath Craftsman in beautiful, suburban Forest Grove. Home features open floor plan, gas fireplace w/ marble tile surround in living area, spacious kitchen featuring quartz countertops, stainless steel appliances & breakfast bar. First floor bedroom/office features French doors. Upstairs boasts a master suite/bath with walk-in closet, two additional bedrooms, and a HUGE bonus room! Large fenced back yard with patio, great for entertaining. Yard includes fenced dog run.",
      "ammenities": [
        
      ]
    },
    "image_count": 20,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.5986282838194!2d-123.13407618443999!3d45.53828197910194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54951959d4ac4be9%3A0x90cc7dedafcf2f8!2s3460%20Oakcrest%20Dr%2C%20Forest%20Grove%2C%20OR%2097116!5e0!3m2!1sen!2sus!4v1578068622715!5m2!1sen!2sus",
        "youtube":"4I4deljxnik"
    }
  }

export default propertyData;

